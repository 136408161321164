<template>
    <div class="supervise">
        <full-scroll></full-scroll>
        <div class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            ">
            <div class="flex flex-pack-center flex-1">
                <img src="@/assets/national_emblem.png" alt="" class="msg-national m_r20" />
                <div class="text_align_last">
                    <p class="font-size24 m_b10">广阳镇人大代表之家</p>
                    <p class="font-size12">
                        GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
                    </p>
                </div>
            </div>
            <div class="msg-title-mid-box font-size32 font-blod flex-1">
                <img src="@/assets/images/tot-top-title.png" alt="" />
                <div class="msg-title-mid-box-title">领心愿</div>
            </div>
            <div class="flex-1 flex-end flex" @click="backHome">
                <div class="my_text_center">
                    <img src="@/assets/images/backhome-new.png" alt="" class="msg-backhome m_b10" />
                    <p>返回首页</p>
                </div>
            </div>
        </div>

        <div class="msg-mid flex flex-v flex-justify-center flex-pack-center">
            <div class="feedback-box flex flex-justify-between">
                <div class="feedback-left">
                    <div class="
                            font-size28 font-blod
                            feedback-left-tot-title
                            color_FF
                            my_text_center
                            m_b10
                        ">
                        领心愿
                    </div>
                    <div class="font-size24 feedback-left-tot-box">
                        <div class=" color_FF padding10 font-blod border-bottom" v-for="(item, id) in tablist" :key="id"
                            @click="glottal(item)" :class="isActived == item.id ? 'actived' : ''">
                            {{ item.label }}
                        </div>
                    </div>
                </div>
                <div class="feedback-right">
                    <div class="feedback-right-box" id="notice-box-list">
                        <ul>
                            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">

                                <li class="notice-list flex flex-pack-center" v-for="(item, id) in superviseList"
                                    :key="id">
                                    <div class="
                                        flex-1
                                        m_r20
                                        flex flex-v flex-justify-center
                                    ">
                                        <div class="notice-list-box color_FF">
                                            <div class="font-size24 m_b20 line-height15">
                                                {{ item.content }}
                                            </div>
                                            <div class="flex flex-pack-center flex-justify-between m_b20">
                                            </div>
                                            <div class="flex flex-pack-center m_r100">
                                                <div class="font-size22 color_9FB flex m_r200">
                                                    <div class=" m_r20">
                                                        发布时间:
                                                    </div>
                                                    <div>
                                                        {{ item.releaseTime }}
                                                    </div>
                                                </div>
                                                <div class="flex  flex-pack-center">
                                                    <div class="m_r20 color_FF">
                                                        状态:
                                                    </div>
                                                    <div
                                                        :class="{ 'bottom_yes': item.status == 1, 'bottom_no': item.status != 1 }">
                                                        {{ item.status == 1 ? "待领取" : "已领取" }}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="color_FF font-size16" v-if="item.status == 1">
                                        <span class="claim_Now" @click="goToDetail(item)">立即领取</span>
                                    </div>
                                    <div class="color_FF font-size16" v-else>
                                        <span class="Received">已认领</span>
                                    </div>
                                </li>
                            </van-list>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            ">
            <div class="my_text_center" @click="goBack">
                <img src="@/assets/images/return-new.png" alt="" class="msg-return m_b10" />
                <p class="font-size24 color_FF">返回上级</p>
            </div>
            <div class="font-blod my_text_center color_FF"></div>

            <div class="my_text_center color_FF">
                <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
                <p class="font-size14">{{ todayTime }}</p>
            </div>
        </div>

        <div class="login" v-if="isShowBehlfLogin">
            <div class="login-boxs">
                <div class="my_text_end font-size24" @click="cancelLogin">关闭</div>

                <p class="my_text_center font-blod font-size32 m_b20 letter-spacing10">登记领取</p>
                <div class=" m_b20">
                    <van-field v-model="realName" label="姓名" placeholder="请输入您的姓名" class="index-login-input" />
                </div>
                <div class="m_b20">
                    <van-field v-model="phone" @input="validateInput" @blur="validate" label="电话" placeholder="请输入您的电话"
                        class="index-login-input" maxlength="11" />
                </div>
                <div class="m_b30">
                    <van-field v-model="remark" rows="2" autosize label="留言" type="textarea" maxlength="50"
                        class="index-login-input " placeholder="请输入留言" show-word-limit />
                </div>

                <div class="my_text_center font-size28 login-sub ">
                    <span @click="behalfLogin">领取</span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { getNewsTopics, wishPaginationList, wishClaim } from "@/request/api.js";
import { dateTimeFormat } from "@/request/until.js";
import fullScroll from "@/components/fullScroll.vue";
import { Toast } from "vant";

export default {
    components: { fullScroll },
    data() {
        return {
            status: 1,
            tablist: [

                {
                    id: 1,
                    label: '待领取'
                }, {
                    id: 2,
                    label: '已领取'
                }, {
                    id: 0,
                    label: '全部'
                },
            ],
            labellum: '',//id
            realName: "",
            phone: "",
            remark: '',
            isShowBehlfLogin: false,//领取弹窗
            todayMtime: "",
            todayTime: "",
            isActived: 1,
            loading: false,
            finished: false,
            totalPage: null,
            pageNum: 1,
            pageSize: 6,
            tid: null,
            isShowSonList: 1, //是否展示子专题新闻列表
            superviseList: [],
            superviseType: [],
            scrollTop: 0,
        };
    },
    created() {
        this.getTime();
    },
    mounted() {
        this.getNewsTopicsList();
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000);
    },
    activated() {
        document.getElementById("notice-box-list").scrollTop = this.scrollTop; // 设置滚动条位置
    },

    methods: {

        glottal(item) {
            console.log(item.id);
            this.isActived = item.id
            this.status = item.id
            this.superviseList = []
            this.pageNum = 1
            this.getFetch();
        },

        validateInput() {
            if (/\D/g.test(this.phone)) {
                Toast.fail('请输入数字');
                this.phone = '';
            }
            if (this.phone.charAt(0) !== '1') {
                Toast.fail('请输入以数字1开头的手机号码');
                this.phone = '';
            }
        },
        validate() {
            if (this.phone.length < 11) {
                Toast.fail('请输入11位手机号');
            }
        },

        behalfLogin() {
            if (this.phone.length < 11) {
                Toast.fail('请输入11位手机号');
            } else {
                wishClaim({
                    realName: this.realName,
                    phone: this.phone,
                    remark: this.remark,
                    id: this.labellum
                })
                    .then(res => {
                        if (res.code == 200) {
                            Toast.success("提交成功，等待审核");
                            this.isShowBehlfLogin = false;
                            this.realName = '';
                            this.phone = '';
                            this.remark = '';
                        }
                        else {
                            Toast.success(res.message);
                        }
                    })
            }

        },

        cancelLogin() {
            this.isShowBehlfLogin = false;
        },
        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = time.split(" ")[1].slice(0, 5);
        },

        getNewsTopicsList() {
            let that = this;
            getNewsTopics({ pid: 11 }).then((res) => {
                let data = res.data;
                data.forEach((item) => {
                    that.superviseType.push({ id: item.id, label: item.name });
                });
                that.tid = that.superviseType[0].id;
                that.getFetch();
            });
        },

        getFetch() {
            wishPaginationList({
                // tid: this.tid,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                status: this.status,
                // isShowSon: this.isShowSonList,
            }).then((res) => {
                this.loading = false;
                if (res.code == 200) {
                    let data = res.data.list;
                    if (data.length == 0) {
                        this.finished = true;
                    } else {
                        if (this.pageNum == res.data.totalPage) {
                            this.finished = true;
                        } else {
                            this.finished = false;
                        }
                        data.forEach((item) => {
                            item.createTime = item.createTime.split(" ")[0];
                        });
                        this.totalPage = res.data.totalPage;
                        this.superviseList.push(...data);
                    }
                }
            });
        },

        onLoad() {
            if (this.totalPage <= this.pageNum) {
                this.loading = false;
                this.finished = true;
            } else {
                this.loading = true;
                this.pageNum++;
                this.getFetch();
            }
        },

        goToDetail(data) {
            this.labellum = data.id
            this.isShowBehlfLogin = true;
        },

        //返回首页
        backHome() {
            this.$router.push("/");
        },
        goBack() {
            this.$router.back();
        },
    },
};
</script>
